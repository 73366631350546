let timeinterval;
export const updateClock = (id, timeoutMins) => {
  const clock = document.getElementById(id);
  const minutesSpan = clock.querySelector('.minutes');
  const secondsSpan = clock.querySelector('.seconds');

  if (timeoutMins > Date.now()) {
    const t = getTimeRemaining(timeoutMins);
    minutesSpan.innerHTML = ('' + t.minutes).slice(-2); // Get rid of zero string
    secondsSpan.innerHTML = ('0' + t.seconds).slice(-2);
  } else {
    minutesSpan.innerHTML = '0'; // Get rid of zero string
    secondsSpan.innerHTML = ('0' + '0').slice(-2);
    clearInterval(timeinterval);

    location.assign('/dashboard');
  }
};

export const getTimeRemaining = (timeoutMins) => {
  const total = timeoutMins - Date.now();
  const seconds = Math.floor((total / 1000) % 60);
  const minutes = Math.floor((total / 1000 / 60) % 60);

  return {
    total,
    minutes,
    seconds,
  };
};

export const startCountdown = (id, timeoutMins) => {
  updateClock(id, timeoutMins - 1000);
  timeinterval = setInterval(() => {
    updateClock(id, timeoutMins);
  }, 1000);
};
