import axios from 'axios';
import { showAlert } from './alerts.js';

export const deactivateAccount = async () => {
  try {
    const res = await axios({
      method: 'PATCH',
      url: '/api/v1/users/deactivateMe',
    });
    if (res.data.status === 'success') {
      showAlert('success', 'Account has been deactivated');
      window.setTimeout(() => {
        location.assign('/');
      }, 5000);
    }
  } catch (err) {
    showAlert('error', error.response.data.msg);
  }
};

export const deleteUserAccount = async () => {
  try {
    const res = await axios({
      method: 'DELETE',
      url: '/api/v1/users/deleteMe',
    });
    if (res.data.status === 'success') {
      showAlert('success', 'Account has been deleted');
      window.setTimeout(() => {
        location.assign('/');
      }, 5000);
    }
  } catch (err) {
    showAlert('error', error.response.data.msg);
  }
};
