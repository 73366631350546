import '@babel/polyfill';
import 'bootstrap';
import { fs, readFileSync } from 'fs';
import {
  login,
  logout,
  addCreditBeforeLogout,
  signup,
  activateNewUserAccountReq,
  forgotPassword,
  passwordReset,
  reactivateAccountSendEmail,
  reactivateUserAccount,
} from './login';
import {
  updateUserInfo,
  addBookInfo,
  updateBookInfo,
  updateEachBooksCredits,
  updateUserInformation,
} from './profile';
import { deleteBook } from './book';
import { deactivateAccount, deleteUserAccount } from './accountStatus';
import {
  backRefreshBrowserClosed,
  goBackToBookSelection,
  confirmRandomBookSelection,
  returnBook,
  removeCreditandGenericId,
  resetRejectedBookList,
} from './selectBook';
import { postReview, reviewConfirmationAuthorisation } from './postBookReview';
import { showAlert } from './alerts';
import { startCountdown } from './countdown';
import { contactUs } from './contact-us';

import {
  adminLogin,
  updateContactUsMessage,
  sendCreditsEmail,
  resetGenres,
  checkAllReviewStatus,
  checkCreditUpdate,
  updateReviewDeadlineAdmin,
  adminActivateUserAccountForm,
} from './admin';
import {
  adminUpdateUserCredits,
  adminAddBookCredits,
  verifyAuthor_Admin,
  verifyUser_Admin,
  verifyBook_Admin,
} from './adminDBUpdate';

// Login / Signup / Log Out
// DOM Elements
const signupForm = document.getElementById('Signup');
const activateNewUserAccount = document.getElementById(
  'activateNewUserAccount'
);
const loginForm = document.getElementById('Login');
const logoutBtn = document.querySelector('.navLink--logout');

const forgotPasswordForm = document.getElementById('form_forgot-password');
const resetPasswordForm = document.getElementById('form_reset-password');
const reactivateAccountForm = document.getElementById(
  'form_reactivate-account'
);
const reactivateAccountPage = document.getElementById('reactivate-account');

const mainDashboard = document.getElementById('dashboard');

const updateUserForm = document.getElementById('updateUserForm');
const updateUserNames = document.getElementById('form_update-account-names');
const updateUserSocial = document.getElementById('form_update-account-social');
const updateUserContact = document.getElementById(
  'form_update-account-contact'
);
const updateUserPassword = document.getElementById(
  'form_update-account-password'
);
const updateUserPhoto = document.getElementById('form_update-account-photo');

const updateBookGenrePreferences = document.getElementById(
  'form_update-account-genre-preferences'
);
const updateDeactivateAccountStatus = document.getElementById(
  'form_account-status-deactivate'
);

const deleteAccount = document.getElementById('form_account-status-delete');

const addBookForm = document.getElementById('addBookForm');
const updateBookForm = document.getElementById('updateBookForm');

const deleteBookPage = document.getElementById('delete-book');
const allBooksPage = document.querySelector('.main_all-books');

const reviewedBook = document.querySelector('.reviewed-book');

const returnBookForm = document.getElementById('return-book_form');
const randomBookSelection = document.getElementById('randomBookSelection');

const chosenBookForm = document.getElementById('chosenBook-form');
const noBooksAvailablePage = document.getElementById('no-books-available-page');

const postReviewForm = document.getElementById('post-review_form');
const authoriseReview = document.getElementById('authorise-review');
const reviewExtension = document.getElementById('form_review-extension');
const getAllUserReviews = document.getElementById('get_all-user-reviews');

const updateCreditForm = document.getElementById('update-credits-form');

const contactUsForm = document.getElementById('email-form_contact-us');

const adminLoginForm = document.getElementById('AdminLoginForm');
const adminDashboard = document.getElementById('main_admin-dashboard');
const adminUpdateContactUsForm = document.getElementById(
  'update_contact-us-page'
);
const adminCompletedQuerysContactUs = document.getElementById(
  'search-option_admin-dashboard'
);
const adminOneUserInfoPage = document.getElementById('get-one-user_admin');
const getOneReviewAdmin = document.getElementById(
  'send-review-update_get-one-review'
);
const getOneBookAdmin = document.getElementById(
  'add-book-credits_get-one-book'
);
const superAdmin = document.getElementById('super-admin');
const importantChecks = document.getElementById(
  'important-checks_admin-dashboard'
);
const runLatestQueryPage = document.getElementById('run-latest-query-page');
const verifyAuthorAdminPage = document.getElementById(
  'verify-author_admin-page'
);
const verifyUserAdminPage = document.getElementById('verify-user_admin-page');
const verifyBookAdminPage = document.getElementById('verify-book_admin-page');
const faqsPage = document.getElementById('faqsPage');

/////////////////////////////////////
// Google Analytics
/////////////////////////////////////
window.dataLayer = window.dataLayer || [];
function gtag() {
  dataLayer.push(arguments);
}
gtag('js', new Date());

gtag('config', 'G-0WYG3XJRLD');

/////////////////////////////////////
// Google Tag Manager
/////////////////////////////////////
window._mfq = window._mfq || [];
(function () {
  var mf = document.createElement('script');
  mf.type = 'text/javascript';
  mf.defer = true;
  mf.src =
    '//cdn.mouseflow.com/projects/6a20a976-ca5b-45e7-9fdf-5aa42b580096.js';
  document.getElementsByTagName('head')[0].appendChild(mf);
})();

/////////////////////////////////////
// Trustpilot
/////////////////////////////////////

// (function (w, d, s, r, n) {
//   w.TrustpilotObject = n;
//   w[n] =
//     w[n] ||
//     function () {
//       (w[n].q = w[n].q || []).push(arguments);
//     };
//   a = d.createElement(s);
//   a.async = 1;
//   a.src = r;
//   a.type = 'text/java' + s;
//   f = d.getElementsByTagName(s)[0];
//   f.parentNode.insertBefore(a, f);
// })(
//   window,
//   document,
//   'script',
//   'https://invitejs.trustpilot.com/tp.min.js',
//   'tp'
// );
// tp('register', 'C1xBqie5fz1fDPTs');

//////////////////////////////////////////
// Global Functions
//////////////////////////////////////////

function loadingModal() {
  const container = document.createElement('div');
  const loadingEl = document.createElement('div');
  container.className = 'loading_modal';
  loadingEl.className = 'loader';
  container.appendChild(loadingEl);
  document.body.prepend(container);
}

//////////////////////////////////////
// Accordion
//////////////////////////////////////

const accordion = document.getElementsByClassName('accordion-container');

for (i = 0; i < accordion.length; i++) {
  accordion[i].addEventListener('click', function () {
    this.classList.toggle('active');
  });
}

/////////////////////////////////////
// Navbar
/////////////////////////////////////

const navbarNotLoggedIn = document.getElementById('not-logged-in');
if (navbarNotLoggedIn) {
  const infoEl = document.querySelectorAll('.nav-list-item')[0];
  if (infoEl) {
    const dropdownOption = document.querySelector('.dropdown-content_nav');
    infoEl.addEventListener('click', () => {
      dropdownOption.classList.toggle('li-block');
    });
  }
}
const navbarLoggedIn = document.getElementById('logged-in');
if (navbarLoggedIn) {
  const loggedInInfoEl = document.querySelectorAll('.nav-list-item')[1];
  if (loggedInInfoEl) {
    const dropdownOption = document.querySelector('.dropdown-content_nav');
    loggedInInfoEl.addEventListener('click', () => {
      dropdownOption.classList.toggle('li-block');
      // If upgrade account info is visible remove it
      const updateProfileNav = document.querySelector(
        '.dropdown-content-logged-in_nav'
      );
      if (updateProfileNav.classList[1] === 'li-block') {
        updateProfileNav.classList.remove('li-block');
      }
    });
  }
}
const imgNavDash = document.querySelector('.img_nav');
if (imgNavDash) {
  const updateProfileNav = document.querySelector(
    '.dropdown-content-logged-in_nav'
  );
  imgNavDash.addEventListener('click', () => {
    updateProfileNav.classList.toggle('li-block');
    // If about dropdown is visible remove it
    const dropdownOption = document.querySelector('.dropdown-content_nav');
    if (dropdownOption.classList[2] === 'li-block') {
      dropdownOption.classList.remove('li-block');
    }
  });
}
document.addEventListener('click', (event) => {
  if (event.target.className === 'logged-in-input-checkbox_nav') {
    const hamburgerDropdownLoggedIn = document.querySelector(
      '.nav-list-logged-in_nav'
    );
    const liFlex = document.querySelector('.li-flex');
    if (!liFlex) {
      hamburgerDropdownLoggedIn.classList.add('li-flex');
    } else if (liFlex) {
      const liBlock = document.querySelector('.li-block');
      hamburgerDropdownLoggedIn.classList.remove('li-flex');
      if (liBlock) {
        liBlock.classList.remove('li-block');
      }
    }
  }
});

///////////////////////////////////////////////
// Re-usable functions
///////////////////////////////////////////////

const updatePlaceholderImage = (imgClass, inputEl) => {
  const imgReader = new FileReader();
  imgReader.onload = () => {
    const coverImg = new Image();
    coverImg.src = imgReader.result;
    let oldImg = document.querySelector(imgClass);

    oldImg.src = coverImg.src;
  };
  imgReader.readAsDataURL(inputEl.files[0]);
};

// Delegation
/////////////////////////////////////////////
// Login, Signup, Logout
//////////////////////////////////////////////
if (signupForm) {
  signupForm.addEventListener('submit', (e) => {
    e.preventDefault();
    const firstName = document.getElementById('inputFirstname').value;
    const lastName = document.getElementById('inputLastname').value;
    const username = document.getElementById('inputUsername').value;
    const email = document.getElementById('inputEmail').value;
    const password = document.getElementById('inputPassword').value;
    const passwordConfirm = document.getElementById('inputPasswordConfirm')
      .value;
    signup(firstName, lastName, username, email, password, passwordConfirm);
    loadingModal();
  });
}

if (activateNewUserAccount) {
  const activateButton = document.querySelector('.button_update-account-names');
  const token = location.pathname.split('/')[3];
  activateButton.addEventListener('click', () => {
    activateNewUserAccountReq(token);
  });
}

if (loginForm)
  loginForm.addEventListener('submit', (e) => {
    e.preventDefault();
    const email = document.getElementById('inputEmail').value;
    const password = document.getElementById('inputPassword').value;
    login(email, password);
  });

if (logoutBtn) {
  const checkPageOnLogout = window.location.pathname;
  const pathNames = checkPageOnLogout.split('/');
  logoutBtn.addEventListener('click', (e) => {
    e.preventDefault();
    if (pathNames[1] === 'selectBook') {
      addCreditBeforeLogout();
    } else {
      logout();
    }
  });
}

////////////////////////////////////////////////////////
// Password Reset
////////////////////////////////////////////////////////

if (forgotPasswordForm) {
  forgotPasswordForm.addEventListener('submit', (e) => {
    e.preventDefault();
    const email = document.getElementById('email_password-reset').value;
    const sendBtn = document.querySelector('.button_update-account-names');
    sendBtn.textContent = 'Sending...';
    forgotPassword({ email }, 'reset');
  });
}

if (resetPasswordForm) {
  resetPasswordForm.addEventListener('submit', (e) => {
    e.preventDefault();
    const password = document.getElementById('password_password-reset').value;
    const passwordConfirm = document.getElementById(
      'confirm-password_password-reset'
    ).value;

    const sendBtn = document.querySelector('.button_update-account-names');
    const token = sendBtn.value;
    sendBtn.textContent = 'Updating...';
    passwordReset({ password, passwordConfirm }, token, 'reset');
  });
}

if (reactivateAccountForm) {
  reactivateAccountForm.addEventListener('submit', (e) => {
    e.preventDefault();
    const email = document.querySelector('.email_reactivate-account').value;
    reactivateAccountSendEmail(email);
  });
}

if (reactivateAccountPage) {
  const reactivateButton = document.querySelector('.button_reactivate-account');
  const token = reactivateButton.value;
  reactivateButton.addEventListener('click', () => {
    reactivateUserAccount(token);
  });
}

////////////////////////////////////////////////////////
// Dashboard
////////////////////////////////////////////////////////

if (mainDashboard) {
  window.addEventListener('click', (e) => {
    if (e.target.classList[0] == 'delete-bin-img_dashboard') {
      const bookId = e.target.dataset.value;
      window.location.href = `/your-books/delete-book/${bookId}`;
    }
  });
  const reviewInfo = document.querySelectorAll('.reviewedby-date');
  if (reviewInfo.length > 0) {
    let earliestDate = reviewInfo[0].textContent;
    var earliestDateEvent = new Date(earliestDate);

    var earliestOptions = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    };
    earliestDate = earliestDateEvent.toLocaleString('en', earliestOptions);

    reviewInfo[0].textContent = earliestDate;
    let latestDate = reviewInfo[1].textContent;
    var latestDateEvent = new Date(latestDate);

    var latestOptions = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    };

    latestDate = latestDateEvent.toLocaleString('en', latestOptions);
    reviewInfo[1].textContent = latestDate;

    // Check Submit button status
    let reviewDateTimeString = reviewInfo[0].textContent;
    reviewDateTimeString = new Date(reviewDateTimeString.replace('at', ''));
    const doesATagHaveRef = document.querySelectorAll(
      '.reviewed-book-button-a'
    )[2];
    if (reviewDateTimeString.getTime() > Date.now() || !doesATagHaveRef.href) {
      const submitButtonStatusTimer = setInterval(() => {
        changeSubmitReviewButtonStatus();
        if (doesATagHaveRef.href) {
          clearInterval(submitButtonStatusTimer);
        }
      }, 1000);
    }
    const changeSubmitReviewButtonStatus = (submitButtonStatusTimer) => {
      if (reviewDateTimeString.getTime() < Date.now()) {
        const pTag = doesATagHaveRef.querySelector('p');
        pTag.remove();
        doesATagHaveRef.href = '/postReview';
        doesATagHaveRef.classList.remove('tooltip');
        doesATagHaveRef.classList.remove('please-wait-submit-review');
      }
    };
  }
  const bookReviewCards = document.querySelectorAll('.book-under-review-info');
  if (bookReviewCards.length > 0) {
    for (let i = 0; i < bookReviewCards.length; i++) {
      const bookReviewCard = document.querySelectorAll(
        '.book-under-review-info'
      )[i];
      const pendingReview = bookReviewCard.querySelector('.status-pending');
      const awaitingReview = bookReviewCard.querySelector('.status-awaiting');
      let k = 0;
      pendingReview ? (k = 4) : (k = 2);
      for (let j = 0; j < k; j++) {
        if (j % 2 === 1) {
          const booksUnderReviewInfo = bookReviewCard.querySelectorAll(
            '.span-book-under-review-date_dashboard'
          )[j];
          let reviewEarliestDate = booksUnderReviewInfo.textContent;
          var reviewEarliestDateEvent = new Date(reviewEarliestDate);
          let reviewEarliestOptions;
          if (k === 2) {
            reviewEarliestOptions = {
              year: 'numeric',
              month: 'long',
              day: 'numeric',
              hour: 'numeric',
              minute: 'numeric',
            };
          } else if (k === 4) {
            reviewEarliestOptions = {
              year: 'numeric',
              month: 'long',
              day: 'numeric',
            };
          }
          reviewEarliestDate = reviewEarliestDateEvent.toLocaleString(
            'en',
            reviewEarliestOptions
          );
          booksUnderReviewInfo.textContent = reviewEarliestDate;
        }
      }
    }
  }
  const selectBook = document.querySelector('.select-random-book_dashboard');
  if (selectBook) selectBook.addEventListener('click', loadingModal);
}

////////////////////////////////////////////////////////
// Update user info
////////////////////////////////////////////////////////

////////////////////////////////////////////////////////
// Update Names
////////////////////////////////////////////////////////

if (updateUserNames) {
  updateUserNames.addEventListener('submit', (e) => {
    e.preventDefault();
    const form = new FormData();
    form.append('firstName', document.getElementById('updateFirstname').value);
    form.append('lastName', document.getElementById('updateLastname').value);
    form.append('username', document.getElementById('updateUsername').value);

    updateUserInformation(form, 'data');
  });
}

if (updateUserSocial) {
  updateUserSocial.addEventListener('submit', (e) => {
    const twitterLink = document.getElementById('updateTwitterUrl').value;
    e.preventDefault();
    const form = new FormData();
    form.append(
      'facebookLink',
      document.getElementById('updateFacebookUrl').value
    );
    form.append(
      'instagramLink',
      document.getElementById('updateInstagramUrl').value
    );
    form.append(
      'twitterLink',
      document.getElementById('updateTwitterUrl').value
    );
    form.append(
      'goodreadsLink',
      document.getElementById('updateGoodreadsUrl').value
    );
    form.append(
      'bookbubLink',
      document.getElementById('updateBookbubUrl').value
    );
    updateUserInformation(form, 'data');
  });
}

if (updateUserContact) {
  updateUserContact.addEventListener('submit', (e) => {
    e.preventDefault();
    const form = new FormData();
    form.append('email', document.getElementById('updateContactEmail').value);
    updateUserInformation(form, 'data');
  });
}

if (updateUserPassword) {
  updateUserPassword.addEventListener('submit', async (e) => {
    e.preventDefault();
    document.querySelector('.button_update-account-names').textContent =
      'Updating..';
    const passwordCurrent = document.getElementById('updateOldPassword').value;
    const password = document.getElementById('updateNewPassword').value;
    const passwordConfirm = document.getElementById('updateConfirmNewPassword')
      .value;

    updateUserInformation(
      { passwordCurrent, password, passwordConfirm },
      'password'
    );
    document.querySelector('.button_update-account-names').textContent = 'Save';
  });
}

if (updateUserPhoto) {
  updateUserPhoto.addEventListener('submit', (e) => {
    e.preventDefault();
    const form = new FormData();
    form.append('photo', document.getElementById('photo').files[0]);
    updateUserInformation(form, 'data');
  });
  const userImg = document.getElementById('photo');

  // Adding in functionality for view a live image of the users photo

  userImg.addEventListener(
    'change',
    () => {
      updatePlaceholderImage('.front-cover-img_update-photo', userImg);
    },
    false
  );
}

if (updateBookGenrePreferences) {
  // Move all currently saved elements into an array
  let checkbox;
  const ul1 = document.querySelector('.query-values-ul1_random-book');
  const ul2 = document.querySelector('.query-values-ul2_random-book');
  const queryInputs = document.querySelectorAll(
    '.form-control_update-account-names'
  );

  const genreData = readFileSync('public/list.json', 'utf-8');
  const genres = JSON.parse(genreData).genres;
  const permGenres = [...genres];
  let genreLikesArray = [];
  let genreDislikesArray = [];

  const likesInput = document.getElementById('updateGenreLikes');
  const dislikesInput = document.getElementById('updateGenreDislikes');

  // Get previously added genres
  const previousLikes = document.querySelectorAll(
    '.likes-option_updateBookPreferences'
  );
  const previousDislikes = document.querySelectorAll(
    '.dislikes-option_updateBookPreferences'
  );
  const container = document.querySelectorAll(
    '.updateBookPreferencesContainer'
  );

  // Created elements
  const noPreferencesSpan = document.createElement('span');
  noPreferencesSpan.className = 'no-preferences_updateBookPreferences';
  noPreferencesSpan.textContent = 'No preferences added yet';

  // Remove Lis when clicking away from elements
  document.addEventListener('click', (e) => {
    if (
      e.target.className !== 'genre-likes_book-preferences' &&
      e.target.className !== 'genre-dislikes_book-preferences' &&
      e.target.className !== 'likes-option_updateBookPreferences' &&
      e.target.className !== 'dislikes-option_updateBookPreferences' &&
      e.target.className !== 'book-genre-input-container_genre-preferences' &&
      e.target.className !== 'form-control_update-account-names'
    ) {
      removeGenresLis('.genre-likes_book-preferences');
      removeGenresLis('.genre-dislikes_book-preferences');
    }
  });

  const addToGenreArray = (previous, array) => {
    previous.forEach((el) => {
      array.push(el.textContent);
      const findIndex = permGenres.indexOf(el.textContent);
      if (findIndex > -1) {
        permGenres.splice(findIndex, 1);
      }
      el.addEventListener('click', (e) => {
        removeGenreFromHTML(e, array);
      });
    });
  };

  if (previousLikes) {
    addToGenreArray(previousLikes, genreLikesArray);
  }
  if (previousDislikes) {
    addToGenreArray(previousDislikes, genreDislikesArray);
  }

  likesInput.addEventListener('keyup', (e) => {
    checkbox = true;
    searchGenreArray(e, genreLikesArray);
  });
  dislikesInput.addEventListener('keyup', (e) => {
    checkbox = false;
    searchGenreArray(e, genreDislikesArray);
  });

  likesInput.onclick = function () {
    queryInputs[0].dispatchEvent(new KeyboardEvent('keyup', { key: 'y' }));
  };
  dislikesInput.onclick = function () {
    queryInputs[1].dispatchEvent(new KeyboardEvent('keyup', { key: 'y' }));
  };

  function removeGenresLis(style) {
    const lis = document.querySelectorAll(style);
    if (lis.length > 0) {
      lis.forEach((elem) => {
        elem.remove();
      });
    }
  }

  function searchGenreArray(e, array) {
    // Remove genres preiously selected
    if (array) {
      array.forEach((pre) => {
        const findIndex = permGenres.indexOf(pre);
        if (findIndex > -1) {
          permGenres.splice(findIndex, 1);
        }
      });
    }
    // Search for genre
    const searchString = e.target.value.toLowerCase();
    const filteredGenres = permGenres.filter((genre) => {
      return genre.toLowerCase().includes(searchString);
    });

    displayGenres(filteredGenres, array);
  }
  function displayGenres(elements, array) {
    // Remove Genres
    removeGenresLis('.genre-dislikes_book-preferences');
    removeGenresLis('.genre-likes_book-preferences');

    // Add Genres
    if (elements.length > 0) {
      for (let i = 0; i < elements.length; i++) {
        const el = document.createElement('p');
        checkbox === true
          ? (el.className = 'genre-likes_book-preferences')
          : (el.className = 'genre-dislikes_book-preferences');
        el.textContent = elements[i];
        el.addEventListener('click', (e) => {
          selectElement(e, array);
          if (checkbox === true) {
            queryInputs[0].dispatchEvent(
              new KeyboardEvent('keyup', { key: 'y' })
            );
          } else {
            queryInputs[1].dispatchEvent(
              new KeyboardEvent('keyup', { key: 'y' })
            );
          }
        });
        // If array is equal to like ul1 else ul2
        checkbox === true ? ul1.append(el) : ul2.append(el);
        // if (i >= 4) break;
      }
    }
  }

  function selectElement(e, array) {
    array.push(e.target.innerHTML);
    e.target.remove();
    addSelectedElementToHTML(e.target.innerHTML, array);
  }
  function addSelectedElementToHTML(text, array) {
    const message = document.createElement('span');
    if (checkbox === true) {
      if (array.length > 0) {
        const container1NoPref = container[0].querySelector(
          '.no-preferences_updateBookPreferences'
        );
        if (container1NoPref) container1NoPref.remove();
      }
      message.className = 'likes-option_updateBookPreferences';
      message.textContent = text;
      message.addEventListener('click', (e) => removeGenreFromHTML(e, array));
      container[0].appendChild(message);
    } else {
      if (array.length > 0) {
        const container2NoPref = container[1].querySelector(
          '.no-preferences_updateBookPreferences'
        );
        if (container2NoPref) container2NoPref.remove();
      }
      message.className = 'dislikes-option_updateBookPreferences';
      message.textContent = text;
      message.addEventListener('click', (e) => removeGenreFromHTML(e, array));
      container[1].appendChild(message);
    }
  }
  function removeGenreFromHTML(e, array) {
    const element = e.target.textContent;
    const findIndex = array.indexOf(element);
    if (findIndex > -1) {
      array.splice(findIndex, 1);
    }
    permGenres.push(element);
    permGenres.sort();
    e.target.remove();
    checkbox === true
      ? removeGenresLis('.genre-likes_book-preferences')
      : removeGenresLis('.genre-dislikes_book-preferences');
    if (e.target.className === 'likes-option_updateBookPreferences') {
      if (array.length < 1) {
        container[0].appendChild(noPreferencesSpan);
      }
    }
    if (e.target.className === 'dislikes-option_updateBookPreferences') {
      if (array.length < 1) {
        container[1].appendChild(noPreferencesSpan);
      }
    }
  }
  updateBookGenrePreferences.addEventListener('submit', (e) => {
    e.preventDefault();
    const form = new FormData();

    if (genreLikesArray.length === 0) {
      form.append('genreLikes', 'undefined');
    } else {
      for (let i = 0; i < genreLikesArray.length; i++) {
        form.append('genreLikes', genreLikesArray[i]);
      }
    }
    if (genreDislikesArray.length === 0) {
      form.append('genreDislikes', 'undefined');
    } else {
      for (let i = 0; i < genreDislikesArray.length; i++) {
        form.append('genreDislikes', genreDislikesArray[i]);
      }
    }
    updateUserInformation(form, 'data');
  });
}

// remove
if (updateUserForm) {
  updateUserForm.addEventListener('submit', (e) => {
    e.preventDefault();
    const form = new FormData();
    for (let j = 0; j < genreDislikesArray.length; j++) {
      form.append('genreDislikes', genreDislikesArray[j]);
    }
    for (let i = 0; i < genreLikesArray.length; i++) {
      form.append('genreLikes', genreLikesArray[i]);
    }
    form.append('photo', document.getElementById('photo').files[0]);
    form.append('twitterLink', document.getElementById('twitter-url').value);
    form.append(
      'instagramLink',
      document.getElementById('instagram-url').value
    );
    form.append('facebookLink', document.getElementById('facebook-url').value);
    form.append(
      'goodreadsLink',
      document.getElementById('goodreads-url').value
    );
    form.append('bookbubLink', document.getElementById('bookbub-url').value);

    updateUserInfo(form, 'data');
  });

  // Likes
  const genreLikesArray = [];
  const genreLikesInput = document.getElementById('genreLikes');
  const genreLikesBtn = document.querySelector('.genreLikesBtn');
  const pushToGenreLikesArray = (e) => {
    e.preventDefault();
    let like = genreLikesInput.value.toLowerCase();
    if (genreLikesArray.includes(like) || genreDislikesArray.includes(like)) {
      return showAlert('error', `You have already added ${like}`);
    }
    if (!like) {
      return;
    }
    genreLikesArray.push(like);
    genreLikesInput.value = '';

    const newLiEl = document.createElement('li');
    newLiEl.className = 'likes-li-el';
    newLiEl.textContent = like;
    newLiEl.addEventListener('click', () => {
      listRoot.removeChild(newLiEl);
    });

    const listRoot = document.querySelector('.genre-likes-root');
    listRoot.append(newLiEl);
  };

  genreLikesBtn.addEventListener('click', pushToGenreLikesArray);

  // // Dislikes
  const genreDislikesArray = [];
  const genreDislikesInput = document.getElementById('genreDislikes');
  const genreDislikesBtn = document.querySelector('.genreDislikesBtn');

  const pushToGenreDislikesArray = (e) => {
    e.preventDefault();
    let dislike = genreDislikesInput.value.toLowerCase();
    if (
      genreDislikesArray.includes(dislike) ||
      genreLikesArray.includes(dislike)
    ) {
      return showAlert('error', `You have already added ${dislike}`);
    }
    if (!dislike) {
      return;
    }
    genreDislikesArray.push(dislike);
    genreDislikesInput.value = '';

    const newLiEl = document.createElement('li');
    newLiEl.className = 'dislikes-li-el';
    newLiEl.textContent = dislike;
    newLiEl.addEventListener('click', () => {
      listRoot.removeChild(newLiEl);
    });

    const listRoot = document.querySelector('.genre-dislikes-root');
    listRoot.append(newLiEl);
  };

  genreDislikesBtn.addEventListener('click', pushToGenreDislikesArray);
}
////////////////////////////////////////////////////////////////
// Account Status
////////////////////////////////////////////////////////////////

if (updateDeactivateAccountStatus) {
  const deactivateButton = document.getElementById('deactivate-account-button');
  deactivateButton.addEventListener('click', () => {
    deactivateButton.textContent = 'Deactivating...';
    deactivateAccount();
  });
}

if (deleteAccount) {
  const modal = document.getElementById('add-modal_delete-account');
  const backdrop = document.querySelector('.backdrop_delete-account');
  const deleteBtn = document.querySelector('.button_delete-account');
  backdrop.addEventListener('click', () => {
    modal.classList.remove('visible');
    backdrop.classList.remove('visible');
  });
  deleteBtn.addEventListener('click', () => {
    modal.classList.add('visible');
    backdrop.classList.add('visible');
  });
  const confirmDeleteButton = document.getElementById('delete-account-button');
  confirmDeleteButton.addEventListener('click', () => {
    deleteUserAccount();
  });
}
////////////////////////////////////////////////////////////////
// Update Books
////////////////////////////////////////////////////////////////

if (addBookForm) {
  addBookForm.addEventListener('submit', (e) => {
    e.preventDefault();
    const form = new FormData();
    form.append('title', document.getElementById('book-input-title').value);
    form.append('frontCover', document.getElementById('frontCover').files[0]);
    form.append(
      'seriesNumber',
      document.getElementById('book-input-series').value
    );
    form.append('asinNumber', document.getElementById('book-input-asin').value);
    form.append(
      'goodreadsLink',
      document.getElementById('book-input-goodread').value
    );
    form.append(
      'bookbubLink',
      document.getElementById('book-input-bookbub').value
    );
    form.append(
      'publishedDate',
      document.getElementById('book-input-published-date').value
    );
    form.append(
      'pageCount',
      document.getElementById('book-input-page-count').value
    );
    form.append(
      'shortDescription',
      document.getElementById('book-info-short-description').value
    );
    form.append(
      'longDescription',
      document.getElementById('book-info-long-description').value
    );
    form.append('price', document.getElementById('book-input-price').value);
    form.append('kindleUnlimited', checkboxValue);
    for (let k = 0; k < genreLikesArray.length; k++) {
      form.append('bookGenre', genreLikesArray[k]);
    }
    for (let i = 0; i < genreTriggersArray.length; i++) {
      form.append('warnings', genreTriggersArray[i]);
    }
    checkForErrors(form);
  });

  const checkForErrors = (form) => {
    const title = document.getElementById('book-input-title').value;
    const asin = document.getElementById('book-input-asin').value;
    const goodreads = document.getElementById('book-input-goodread').value;
    const pageCount = document.getElementById('book-input-page-count').value;

    const errorMessages = document.querySelectorAll('.missing-field_add-book');
    const updateVisibility = (num, type) => {
      errorMessages[num].style.display = type;
    };
    if (!title || !asin || !goodreads || !pageCount) {
      if (!title) {
        updateVisibility(0, 'block');
      } else {
        updateVisibility(0, 'none');
      }
      if (!asin) {
        updateVisibility(1, 'block');
      } else {
        updateVisibility(1, 'none');
      }
      if (!goodreads) {
        updateVisibility(2, 'block');
      } else {
        updateVisibility(2, 'none');
      }
      if (!pageCount) {
        updateVisibility(3, 'block');
      } else {
        updateVisibility(3, 'none');
      }
      document.body.scrollTo({ top: 50, behavior: 'smooth' });
    } else {
      addBookInfo(form, 'data');
      loadingModal();
    }
  };

  const frontCoverInput = document.getElementById('frontCover');

  frontCoverInput.addEventListener(
    'change',
    () => {
      updatePlaceholderImage('.front-cover-img_add-book', frontCoverInput);
    },
    false
  );

  const checkboxInput = document.getElementById('book-input-kindle-unlimited');
  let checkboxValue = 'false';
  checkboxInput.addEventListener('click', () => {
    if (document.querySelector('input[type="checkbox"]:checked')) {
      checkboxInput.value = 'true';
      checkboxValue = checkboxInput.value;
    } else {
      checkboxInput.value = 'false';
      checkboxValue = checkboxInput.value;
    }
  });

  let checkbox;
  const ul1 = document.querySelector('.query-values-ul1_update-book');
  const ul2 = document.querySelector('.query-values-ul2_update-book');
  const genreInput = document.getElementById('bookGenreAdd');
  const triggerInput = document.getElementById('genreWarnings');

  const genreData = readFileSync('public/list.json', 'utf-8');
  const genres = JSON.parse(genreData).genres;
  const triggers = JSON.parse(genreData).triggers;
  const permGenres = [...genres];
  const permTriggers = [...triggers];
  let genreLikesArray = [];
  let genreTriggersArray = [];

  const likesInput = document.getElementById('bookGenreAdd');
  const triggersInput = document.getElementById('genreWarnings');

  // Get previously added genres
  const previousGenres = document.querySelectorAll('.add-genre-li-el');
  const previousTriggers = document.querySelectorAll('.warning-li-el');
  const container1 = document.querySelector('.genres-root');
  const container2 = document.querySelector('.warnings-root');

  // Created elements Remove?
  const noGenresP = document.createElement('p');
  noGenresP.className = 'no-genres_update-book';
  noGenresP.textContent = 'No genres added yet';
  const noTriggersP = document.createElement('p');
  noTriggersP.className = 'no-triggers_update-book';
  noTriggersP.textContent = 'No triggers added yet';

  // Remove Lis when clicking away from elements
  document.addEventListener('click', (e) => {
    if (
      e.target.className !== 'genre-input_update-book' &&
      e.target.className !== 'add-genre-li-el' &&
      e.target.className !== 'genre-input_update-book' &&
      e.target.className !== 'warning-li-el' &&
      e.target.className !== 'genre-likes_book-preferences' &&
      e.target.className !== 'genre-dislikes_book-preferences'
    ) {
      removeGenresLis('.genre-likes_book-preferences');
      removeGenresLis('.genre-dislikes_book-preferences');
    }
  });

  const addToGenreArray = (previous, array, permGenres) => {
    previous.forEach((el) => {
      array.push(el.textContent);
      const findIndex = permGenres.indexOf(el.textContent);
      if (findIndex > -1) {
        permGenres.splice(findIndex, 1);
      }
      el.addEventListener('click', (e) => {
        removeGenreFromHTML(e, array, permGenres);
      });
    });
  };

  if (previousGenres) {
    addToGenreArray(previousGenres, genreLikesArray, permGenres);
  }
  if (previousTriggers) {
    addToGenreArray(previousTriggers, genreTriggersArray, permTriggers);
  }

  likesInput.addEventListener('keyup', (e) => {
    checkbox = true;
    searchGenreArray(e, genreLikesArray, permGenres);
  });
  triggersInput.addEventListener('keyup', (e) => {
    checkbox = false;
    searchGenreArray(e, genreTriggersArray, permTriggers);
  });

  likesInput.onclick = function () {
    genreInput.dispatchEvent(new KeyboardEvent('keyup', { key: 'y' }));
  };
  triggersInput.onclick = function () {
    triggerInput.dispatchEvent(new KeyboardEvent('keyup', { key: 'y' }));
  };

  function removeGenresLis(style) {
    const lis = document.querySelectorAll(style);
    if (lis.length > 0) {
      lis.forEach((elem) => {
        elem.remove();
      });
    }
  }

  function searchGenreArray(e, array, permArray) {
    // Remove genres preiously selected
    if (array) {
      array.forEach((pre) => {
        const findIndex = permArray.indexOf(pre);
        if (findIndex > -1) {
          permArray.splice(findIndex, 1);
        }
      });
    }
    // Search for genre
    const searchString = e.target.value.toLowerCase();
    const filteredGenres = permArray.filter((genre) => {
      return genre.toLowerCase().includes(searchString);
    });

    displayGenres(filteredGenres, array, permArray);
  }
  function displayGenres(elements, array, permArray) {
    // Remove Genres
    removeGenresLis('.genre-dislikes_book-preferences');
    removeGenresLis('.genre-likes_book-preferences');

    // Add Genres
    if (elements.length > 0) {
      for (let i = 0; i < elements.length; i++) {
        const el = document.createElement('p');
        checkbox === true
          ? (el.className = 'genre-likes_book-preferences')
          : (el.className = 'genre-dislikes_book-preferences');
        el.textContent = elements[i];
        el.addEventListener('click', (e) => {
          selectElement(e, array, permArray);
          if (checkbox === true) {
            genreInput.dispatchEvent(new KeyboardEvent('keyup', { key: 'y' }));
          } else {
            triggerInput.dispatchEvent(
              new KeyboardEvent('keyup', { key: 'y' })
            );
          }
        });
        // If array is equal to like ul1 else ul2
        checkbox === true ? ul1.append(el) : ul2.append(el);
        // if (i >= 4) break;
      }
    }
  }

  function selectElement(e, array, permArray) {
    array.push(e.target.innerHTML);
    e.target.remove();
    addSelectedElementToHTML(e.target.innerHTML, array, permArray);
  }
  function addSelectedElementToHTML(text, array, permArray) {
    const message = document.createElement('span');
    const noGenreContainers = document.querySelectorAll(
      '.genre-container_update-book'
    );
    if (checkbox === true) {
      if (array.length > 0) {
        const noGenresAdded = noGenreContainers[0].querySelector(
          '.no-genres_update-book'
        );
        if (noGenresAdded) noGenresAdded.remove();
      }
      message.className = 'add-genre-li-el';
      message.textContent = text;
      message.addEventListener('click', (e) =>
        removeGenreFromHTML(e, array, permArray)
      );
      container1.appendChild(message);
    } else {
      if (array.length > 0) {
        const noTriggersAdded = noGenreContainers[1].querySelector(
          '.no-triggers_update-book'
        );
        if (noTriggersAdded) noTriggersAdded.remove();
      }
      message.className = 'warning-li-el';
      message.textContent = text;
      message.addEventListener('click', (e) =>
        removeGenreFromHTML(e, array, permArray)
      );
      container2.appendChild(message);
    }
  }
  function removeGenreFromHTML(e, array, permArray) {
    const noGenreContainers = document.querySelectorAll(
      '.genre-container_update-book'
    );
    const element = e.target.textContent;
    const findIndex = array.indexOf(element);
    if (findIndex > -1) {
      array.splice(findIndex, 1);
    }
    permArray.push(element);
    permArray.sort();
    e.target.remove();
    checkbox === true
      ? removeGenresLis('.genre-likes_book-preferences')
      : removeGenresLis('.genre-dislikes_book-preferences');
    if (e.target.className === 'add-genre-li-el') {
      if (array.length < 1) {
        const noGenresAdded = noGenreContainers[0];
        noGenresAdded.appendChild(noGenresP);
      }
    }
    if (e.target.className === 'warning-li-el') {
      if (array.length < 1) {
        const noTriggersAdded = noGenreContainers[1];
        noTriggersAdded.appendChild(noTriggersP);
      }
    }
  }
  const backBtn = document.querySelector('.skip');
  backBtn.addEventListener('click', () => {
    window.history.back();
  });
}

if (updateBookForm) {
  updateBookForm.addEventListener('submit', (e) => {
    e.preventDefault();

    const form = new FormData();
    form.append('title', document.getElementById('book-input-title').value);
    form.append('frontCover', document.getElementById('frontCover').files[0]);
    form.append(
      'seriesNumber',
      document.getElementById('book-input-series').value
    );
    form.append('asinNumber', document.getElementById('book-input-asin').value);
    form.append(
      'goodreadsLink',
      document.getElementById('book-input-goodread').value
    );
    form.append(
      'bookbubLink',
      document.getElementById('book-input-bookbub').value
    );
    form.append(
      'publishedDate',
      document.getElementById('book-input-published-date').value
    );
    form.append(
      'pageCount',
      document.getElementById('book-input-page-count').value
    );
    form.append(
      'shortDescription',
      document.getElementById('book-info-short-description').value
    );
    form.append(
      'longDescription',
      document.getElementById('book-info-long-description').value
    );
    form.append('price', document.getElementById('book-input-price').value);
    form.append('kindleUnlimited', checkboxValue);
    for (let k = 0; k < genreLikesArray.length; k++) {
      form.append('bookGenre', genreLikesArray[k]);
    }
    for (let i = 0; i < genreTriggersArray.length; i++) {
      form.append('warnings', genreTriggersArray[i]);
    }
    const userId = document.querySelector('.updateBtn').value;
    checkForErrors(form, userId);
  });

  const checkForErrors = (form, userId) => {
    const title = document.getElementById('book-input-title').value;
    const asin = document.getElementById('book-input-asin').value;
    const goodreads = document.getElementById('book-input-goodread').value;
    const pageCount = document.getElementById('book-input-page-count').value;

    const errorMessages = document.querySelectorAll('.missing-field_add-book');
    const updateVisibility = (num, type) => {
      errorMessages[num].style.display = type;
    };
    if (!title || !asin || !goodreads || !pageCount) {
      if (!title) {
        updateVisibility(0, 'block');
      } else {
        updateVisibility(0, 'none');
      }
      if (!asin) {
        updateVisibility(1, 'block');
      } else {
        updateVisibility(1, 'none');
      }
      if (!goodreads) {
        updateVisibility(2, 'block');
      } else {
        updateVisibility(2, 'none');
      }
      if (!pageCount) {
        updateVisibility(3, 'block');
      } else {
        updateVisibility(3, 'none');
      }
      document.body.scrollTo({ top: 50, behavior: 'smooth' });
    } else {
      updateBookInfo(form, 'data', userId);
      loadingModal();
    }
  };

  const frontCoverInput = document.getElementById('frontCover');

  frontCoverInput.addEventListener(
    'change',
    () => {
      updatePlaceholderImage('.front-cover-img_update-book', frontCoverInput);
    },
    false
  );

  const checkboxInput = document.getElementById('book-input-kindle-unlimited');
  let checkboxValue;
  if (document.querySelector('input[type="checkbox"]:checked')) {
    checkboxValue = 'true';
  } else {
    checkboxValue = 'false';
  }
  checkboxInput.addEventListener('click', () => {
    if (document.querySelector('input[type="checkbox"]:checked')) {
      checkboxInput.value = 'true';
      checkboxValue = checkboxInput.value;
    } else {
      checkboxInput.value = 'false';
      checkboxValue = checkboxInput.value;
    }
  });

  let checkbox;
  const ul1 = document.querySelector('.query-values-ul1_update-book');
  const ul2 = document.querySelector('.query-values-ul2_update-book');
  const genreInput = document.getElementById('bookGenreAdd');
  const triggerInput = document.getElementById('genreWarnings');

  const genreData = readFileSync('public/list.json', 'utf-8');
  const genres = JSON.parse(genreData).genres;
  const triggers = JSON.parse(genreData).triggers;
  const permGenres = [...genres];
  const permTriggers = [...triggers];
  let genreLikesArray = [];
  let genreTriggersArray = [];

  const likesInput = document.getElementById('bookGenreAdd');
  const triggersInput = document.getElementById('genreWarnings');

  // Get previously added genres
  const previousGenres = document.querySelectorAll('.add-genre-li-el');
  const previousTriggers = document.querySelectorAll('.warning-li-el');
  const container1 = document.querySelector('.genres-root');
  const container2 = document.querySelector('.warnings-root');

  // Created elements Remove?
  const noGenresP = document.createElement('p');
  noGenresP.className = 'no-genres_update-book';
  noGenresP.textContent = 'No genres added yet';
  const noTriggersP = document.createElement('p');
  noTriggersP.className = 'no-triggers_update-book';
  noTriggersP.textContent = 'No triggers added yet';

  // Remove Lis when clicking away from elements
  document.addEventListener('click', (e) => {
    if (
      e.target.className !== 'genre-input_update-book' &&
      e.target.className !== 'add-genre-li-el' &&
      e.target.className !== 'genre-input_update-book' &&
      e.target.className !== 'warning-li-el' &&
      e.target.className !== 'genre-likes_book-preferences' &&
      e.target.className !== 'genre-dislikes_book-preferences'
    ) {
      removeGenresLis('.genre-likes_book-preferences');
      removeGenresLis('.genre-dislikes_book-preferences');
    }
  });

  const addToGenreArray = (previous, array, permGenres) => {
    previous.forEach((el) => {
      array.push(el.textContent);
      const findIndex = permGenres.indexOf(el.textContent);
      if (findIndex > -1) {
        permGenres.splice(findIndex, 1);
      }
      el.addEventListener('click', (e) => {
        removeGenreFromHTML(e, array, permGenres);
      });
    });
  };

  if (previousGenres) {
    addToGenreArray(previousGenres, genreLikesArray, permGenres);
  }
  if (previousTriggers) {
    addToGenreArray(previousTriggers, genreTriggersArray, permTriggers);
  }

  likesInput.addEventListener('keyup', (e) => {
    checkbox = true;
    searchGenreArray(e, genreLikesArray, permGenres);
  });
  triggersInput.addEventListener('keyup', (e) => {
    checkbox = false;
    searchGenreArray(e, genreTriggersArray, permTriggers);
  });

  likesInput.onclick = function () {
    genreInput.dispatchEvent(new KeyboardEvent('keyup', { key: 'y' }));
  };
  triggersInput.onclick = function () {
    triggerInput.dispatchEvent(new KeyboardEvent('keyup', { key: 'y' }));
  };

  function removeGenresLis(style) {
    const lis = document.querySelectorAll(style);
    if (lis.length > 0) {
      lis.forEach((elem) => {
        elem.remove();
      });
    }
  }

  function searchGenreArray(e, array, permArray) {
    // Remove genres preiously selected
    if (array) {
      array.forEach((pre) => {
        const findIndex = permArray.indexOf(pre);
        if (findIndex > -1) {
          permArray.splice(findIndex, 1);
        }
      });
    }
    // Search for genre
    const searchString = e.target.value.toLowerCase();
    const filteredGenres = permArray.filter((genre) => {
      return genre.toLowerCase().includes(searchString);
    });

    displayGenres(filteredGenres, array, permArray);
  }
  function displayGenres(elements, array, permArray) {
    // Remove Genres
    removeGenresLis('.genre-dislikes_book-preferences');
    removeGenresLis('.genre-likes_book-preferences');

    // Add Genres
    if (elements.length > 0) {
      for (let i = 0; i < elements.length; i++) {
        const el = document.createElement('p');
        checkbox === true
          ? (el.className = 'genre-likes_book-preferences')
          : (el.className = 'genre-dislikes_book-preferences');
        el.textContent = elements[i];
        el.addEventListener('click', (e) => {
          selectElement(e, array, permArray);
          if (checkbox === true) {
            genreInput.dispatchEvent(new KeyboardEvent('keyup', { key: 'y' }));
          } else {
            triggerInput.dispatchEvent(
              new KeyboardEvent('keyup', { key: 'y' })
            );
          }
        });
        // If array is equal to like ul1 else ul2
        checkbox === true ? ul1.append(el) : ul2.append(el);
        // if (i >= 4) break;
      }
    }
  }

  function selectElement(e, array, permArray) {
    array.push(e.target.innerHTML);
    e.target.remove();
    addSelectedElementToHTML(e.target.innerHTML, array, permArray);
  }
  function addSelectedElementToHTML(text, array, permArray) {
    const message = document.createElement('span');
    const noGenreContainers = document.querySelectorAll(
      '.genre-container_update-book'
    );
    if (checkbox === true) {
      if (array.length > 0) {
        const noGenresAdded = noGenreContainers[0].querySelector(
          '.no-genres_update-book'
        );
        if (noGenresAdded) noGenresAdded.remove();
      }
      message.className = 'add-genre-li-el';
      message.textContent = text;
      message.addEventListener('click', (e) =>
        removeGenreFromHTML(e, array, permArray)
      );
      container1.appendChild(message);
    } else {
      if (array.length > 0) {
        const noTriggersAdded = noGenreContainers[1].querySelector(
          '.no-triggers_update-book'
        );
        if (noTriggersAdded) noTriggersAdded.remove();
      }
      message.className = 'warning-li-el';
      message.textContent = text;
      message.addEventListener('click', (e) =>
        removeGenreFromHTML(e, array, permArray)
      );
      container2.appendChild(message);
    }
  }
  function removeGenreFromHTML(e, array, permArray) {
    const noGenreContainers = document.querySelectorAll(
      '.genre-container_update-book'
    );
    const element = e.target.textContent;
    const findIndex = array.indexOf(element);
    if (findIndex > -1) {
      array.splice(findIndex, 1);
    }
    permArray.push(element);
    permArray.sort();
    e.target.remove();
    checkbox === true
      ? removeGenresLis('.genre-likes_book-preferences')
      : removeGenresLis('.genre-dislikes_book-preferences');
    if (e.target.className === 'add-genre-li-el') {
      if (array.length < 1) {
        const noGenresAdded = noGenreContainers[0];
        noGenresAdded.appendChild(noGenresP);
      }
    }
    if (e.target.className === 'warning-li-el') {
      if (array.length < 1) {
        const noTriggersAdded = noGenreContainers[1];
        noTriggersAdded.appendChild(noTriggersP);
      }
    }
  }

  const backBtn = document.querySelector('.skip');
  backBtn.addEventListener('click', () => {
    window.history.back();
  });
}

///////////////////////////////////////////////////////////////
// Delete Book
///////////////////////////////////////////////////////////////

if (allBooksPage) {
  window.addEventListener('click', (e) => {
    if (e.target.classList[0] == 'delete-bin-img_all-books') {
      const bookId = e.target.dataset.value;
      console.log(bookId);
      window.location.href = `/your-books/delete-book/${bookId}`;
    }
  });
}

if (deleteBookPage) {
  const deleteButton = document.querySelector('.button_delete-book');
  const bookId = deleteButton.value;
  const elementBody = document.querySelector('.outer-container_delete-book');
  const bookTitle = document.querySelector('.title_delete-book').textContent;

  deleteButton.addEventListener('click', (e) => {
    e.preventDefault();

    const modalBackdrop = document.createElement('div');
    modalBackdrop.className = 'backdrop visible';

    const modal = document.createElement('div');
    modal.className = 'modal_delete-book visible';

    const modalContent = document.createElement('div');
    modalContent.className = 'modal-content';

    const h2Element = document.createElement('h2');
    h2Element.className = 'modal-h2 modal-h2_delete-book';
    h2Element.textContent = `Are you sure you want to delete "${bookTitle}"?`;

    const h3Element = document.createElement('h3');
    h3Element.className = 'modal-h3';
    h3Element.textContent =
      'This action is irreversible and will be reflected across your account';

    const buttonDiv = document.createElement('div');
    buttonDiv.className = 'button-div-modal_delete-book';

    const cancelButton = document.createElement('button');
    cancelButton.textContent = 'Cancel';
    cancelButton.className = 'cancel-button_delete-book';
    cancelButton.addEventListener('click', () => {
      const removeBackdrop = document.querySelector('.backdrop');
      const removeModal = document.querySelector('.modal');
      removeBackdrop.classList.remove('visible');
      removeModal.classList.remove('visible');
    });

    const createDeleteButton = document.createElement('button');
    createDeleteButton.textContent = 'Delete';
    createDeleteButton.className = 'delete-button_delete-book';
    createDeleteButton.addEventListener('click', () => {
      createDeleteButton.textContent = 'deleting...';
      deleteBook(bookId);
    });

    elementBody.prepend(modalBackdrop);
    modalBackdrop.append(modal);
    modal.append(modalContent);
    modalContent.append(h2Element, h3Element, buttonDiv);
    buttonDiv.append(cancelButton, createDeleteButton);
  });
}

///////////////////////////////////////////////////////////////
// Select Random Books
///////////////////////////////////////////////////////////////

///////////////////////////////////////////////////////////////
// Select Random Book
///////////////////////////////////////////////////////////////

if (randomBookSelection) {
  const countdownTimer = Date.now() + 60 * 10 * 1000;
  startCountdown('countdown-clock', countdownTimer);

  sessionStorage.setItem('followLink', 'undefined');
  // Set session storage for chosen book page
  sessionStorage.setItem('chosenbook', 'true');

  const chooseAgainBtn = document.getElementById('choose-new-book');
  chooseAgainBtn.addEventListener('click', () => {
    // Add loading modal
    loadingModal();
    // Update session storage
    sessionStorage.setItem('followLink', 'true');
  });

  const selectedBook = document.getElementById('selectedBook');
  selectedBook.addEventListener('click', () => {
    sessionStorage.setItem('followLink', 'true');
    sessionStorage.setItem('resetCache', 'true');
    loadingModal();
  });

  window.onbeforeunload = (e) => {
    if (sessionStorage.getItem('followLink') == 'undefined') {
      // *********** perform database operation here
      // before closing the browser ************** //
      backRefreshBrowserClosed();
      // added the delay otherwise database operation will not work
      for (var i = 0; i < 50000000; i++) {}
    }
  };

  let checkboxValue;
  const urlStatus = location.href;
  const modal = document.querySelector('.search-modal-background_random-book');
  const searchMenu = document.querySelector('.search-modal_random-book');
  if (urlStatus.includes('checkboxValue')) {
    modal.style.visibility = 'hidden';
    searchMenu.classList.remove('search-modal-transition_random-book');
  } else if (urlStatus.includes('?')) {
    modal.style.visibility = 'visible';
    searchMenu.classList.add('search-modal-transition_random-book');
  } else {
    modal.style.visibility = 'hidden';
    searchMenu.classList.remove('search-modal-transition_random-book');
  }
  const filterBtn = document.querySelector('.search-bar-icon');
  filterBtn.addEventListener('click', () => {
    modal.style.visibility = 'visible';
    searchMenu.classList.add('search-modal-transition_random-book');
  });

  const filterCloseBtn = document.querySelector('.i_block_random-book');
  filterCloseBtn.addEventListener('click', () => {
    searchMenu.classList.remove('search-modal-transition_random-book');
    setTimeout(() => {
      modal.style.visibility = 'hidden';
    }, 500);
  });

  const radio1 = document.getElementById('radio1_random-book');
  const radio2 = document.getElementById('radio2_random-book');
  const radio3 = document.getElementById('radio3_random-book');

  const ul = document.querySelector('.query-values-ul_random-book');
  const queryInput = document.querySelector('.search-query_random-book');

  const genreData = readFileSync('public/list.json', 'utf-8');
  const genres = JSON.parse(genreData).genres;
  const permSavedGenres = [...genres];
  const permSearchedGenres = [...genres];
  let savedGenresArray = JSON.parse(radio1.value);
  const searchedGenresArray = [];

  //////////////////////
  // Functions
  //////////////////////
  const updateFromPreviousGenres = (array, permArray) => {
    const urlParams = new URLSearchParams(window.location.search);
    const myParam = urlParams.get('array').split(',');
    myParam.forEach((el) => {
      if (!array.includes(el)) {
        array.push(el);
      }
      array.forEach((el) => {
        const findIndex = permArray.indexOf(el);
        if (findIndex > -1) {
          permArray.splice(findIndex, 1);
        }
      });
    });
  };

  const setInputStyle = (attr1, attr2) => {
    queryInput.removeAttribute(`${attr1}d`, '');
    queryInput.classList.remove(`search-query-${attr1}_random-book`);
    queryInput.setAttribute(`${attr2}d`, '');
    queryInput.classList.add(`search-query-${attr2}_random-book`);
  };

  const addNoGenreMessage = () => {
    const container = document.querySelector(
      '.selected-query-values-div_random-book'
    );
    const addAGenreMessage = document.createElement('p');
    addAGenreMessage.textContent = 'Click below to filter by genre';
    addAGenreMessage.className = 'add-genre-message_select-book';
    container.append(addAGenreMessage);
  };

  const removeNoGenreMessage = () => {
    const noGenreMessage = document.querySelector(
      '.add-genre-message_select-book'
    );
    noGenreMessage.remove();
  };

  queryInput.addEventListener('keyup', (e) => {
    if (checkboxValue === true) {
      searchGenreArray(e, savedGenresArray, permSavedGenres);
    } else if (checkboxValue === false) {
      searchGenreArray(e, searchedGenresArray, permSearchedGenres);
    }
  });
  const updatePageInfo = (val, array, permArray) => {
    checkboxValue = val;
    array.forEach((el) => {
      if (permArray.includes(el)) {
        const findIndex = permArray.indexOf(el);
        if (findIndex > -1) {
          permArray.splice(findIndex, 1);
        }
      }
    });
    removeGenresLis('.selected-genre_random-book');
    const container = document.querySelector(
      '.selected-query-values-div_random-book'
    );
    const noGenreMessage = document.querySelector(
      '.add-genre-message_select-book'
    );
    for (let i = 0; i < array.length; i++) {
      const message = document.createElement('p');
      message.className = 'selected-genre_random-book';
      message.textContent = array[i];
      message.addEventListener('click', (e) =>
        removeGenreFromHTML(e, array, permArray)
      );
      container.appendChild(message);
    }
    if (array.length < 1 && !noGenreMessage) addNoGenreMessage();
    if (array.length >= 1 && noGenreMessage) removeNoGenreMessage();
    queryInput.dispatchEvent(new KeyboardEvent('keyup', { key: 'y' }));
  };

  radio1.addEventListener('click', (e) => {
    updatePageInfo(true, savedGenresArray, permSavedGenres);
    setInputStyle('disable', 'enable');
  });

  radio2.addEventListener('click', () => {
    updatePageInfo(false, searchedGenresArray, permSearchedGenres);
    setInputStyle('disable', 'enable');
  });
  radio3.addEventListener('click', () => {
    checkboxValue = undefined;
    removeGenresLis('.selected-genre_random-book');
    removeGenresLis('.genre_random-book');
    setInputStyle('enable', 'disable');
  });

  if (location.href.includes('?')) {
    if (location.search.includes('true')) {
      updateFromPreviousGenres(savedGenresArray, permSavedGenres);
      radio1.click();
      setInputStyle('disable', 'enable');
    } else if (location.search.includes('false')) {
      updateFromPreviousGenres(searchedGenresArray, permSearchedGenres);
      radio2.click();
      setInputStyle('disable', 'enable');
    }
  } else {
    radio3.click();
    setInputStyle('enable', 'disable');
  }
  function searchGenreArray(e, array, permArray) {
    // Remove genres preiously selected
    if (array) {
      array.forEach((pre) => {
        const findIndex = permArray.indexOf(pre);
        if (findIndex > -1) {
          permArray.splice(findIndex, 1);
        }
      });
    }
    // Search for genre
    const searchString = e.target.value.toLowerCase();
    const filteredGenres = permArray.filter((genre) => {
      return genre.toLowerCase().includes(searchString);
    });

    displayGenres(filteredGenres, array, permArray);
  }
  function displayGenres(elements, array, permArray) {
    // Remove Genres
    removeGenresLis('.genre_random-book');

    // Add Genres
    if (elements.length > 0) {
      for (let i = 0; i < elements.length; i++) {
        const el = document.createElement('p');
        el.className = 'genre_random-book';
        el.textContent = elements[i];
        el.addEventListener('click', (e) => {
          selectElement(e, array, permArray);
          queryInput.dispatchEvent(new KeyboardEvent('keyup', { key: 'y' }));
        });
        ul.append(el);
        // if (i >= 9) break;
      }
    }
  }
  function removeGenresLis(style) {
    const lis = document.querySelectorAll(style);
    if (lis.length > 0) {
      lis.forEach((elem) => {
        elem.remove();
      });
    }
  }
  function selectElement(e, array, permArray) {
    array.push(e.target.innerHTML);
    e.target.remove();
    addSelectedElementToHTML(e.target.innerHTML, array, permArray);
  }
  function addSelectedElementToHTML(text, array, permArray) {
    const container = document.querySelector(
      '.selected-query-values-div_random-book'
    );
    const noGenreMessage = document.querySelector(
      '.add-genre-message_select-book'
    );
    const message = document.createElement('p');
    message.className = 'selected-genre_random-book';
    message.textContent = text;
    message.addEventListener('click', (e) => {
      removeGenreFromHTML(e, array, permArray);
    });
    container.appendChild(message);
    if (array.length < 1 && !noGenreMessage) addNoGenreMessage();
    if (array.length >= 1 && noGenreMessage) removeNoGenreMessage();
  }
  function removeGenreFromHTML(e, array, permArray) {
    const noGenreMessage = document.querySelector(
      '.add-genre-message_select-book'
    );
    const element = e.target.textContent;
    const findIndex = array.indexOf(element);
    if (findIndex > -1) {
      array.splice(findIndex, 1);
    }
    permArray.push(element);
    permArray.sort();
    e.target.remove();
    if (array.length < 1 && !noGenreMessage) addNoGenreMessage();

    queryInput.dispatchEvent(new KeyboardEvent('keyup', { key: 'y' }));
  }
  const searchFilteredOptions = document.getElementById(
    'search-filtered-options_random-book'
  );
  function createQueryString(checkbox, array) {
    let string = location.href.split('?')[0];
    if (array && array.length > 0) {
      string = `${string}?checkboxValue=${checkbox}&array=${array}`;
    }
    return string;
  }
  const setQueryString = () => {
    let queryString;
    if (checkboxValue === true) {
      queryString = createQueryString(checkboxValue, savedGenresArray);
    } else if (checkboxValue === false) {
      queryString = createQueryString(checkboxValue, searchedGenresArray);
    } else {
      queryString = createQueryString(checkboxValue);
    }
    return queryString;
  };
  searchFilteredOptions.addEventListener('click', (e) => {
    const newUrl = setQueryString(checkboxValue);
    searchFilteredOptions.href = newUrl;
    loadingModal();
  });

  const nextBookBtn = document.getElementById('choose-new-book');
  nextBookBtn.addEventListener('click', () => {
    const newUrl = setQueryString(checkboxValue);
    nextBookBtn.href = newUrl;
  });
}
///////////////////////////////////////////////////////////////
// Chosen Random Book
///////////////////////////////////////////////////////////////

if (chosenBookForm) {
  // Added block to force page reload to prevent users going back pages
  for (var i = 0; i < 500000000; i++) {}
  if (sessionStorage.getItem('resetCache') === 'false') {
    sessionStorage.setItem('resetCache', 'true');
    window.location.replace(window.location.href);
  }

  const countdownTimer = Date.now() + 30 * 60 * 1000;
  startCountdown('countdown-clock', countdownTimer);

  const bookId = document.querySelector('.selectBookBtn').value;

  sessionStorage.setItem('normalfunction', 'false');
  if (sessionStorage.getItem('chosenbook') === 'true') {
  } else if (sessionStorage.getItem('chosenbook') === 'false') {
    removeCreditandGenericId(bookId);
  }
  //Return book
  const updateBtn = document.querySelector('.updateBtn_chosen-book');
  updateBtn.addEventListener('click', (e) => {
    sessionStorage.setItem('normalfunction', 'true');
    sessionStorage.setItem('resetCache', 'false');
    e.preventDefault();
    goBackToBookSelection(bookId);
    loadingModal();
  });

  //Select book
  const selectRandomBookBtn = document.querySelector('.selectBookBtn');
  selectRandomBookBtn.addEventListener('click', (e) => {
    sessionStorage.setItem('resetCache', 'false');
    sessionStorage.setItem('normalfunction', 'true');
    e.preventDefault();
    confirmRandomBookSelection(bookId);
    loadingModal();
  });

  // If anything but a link is clicked on backRefreshBrowserClosed function will run
  window.onbeforeunload = (e) => {
    if (sessionStorage.getItem('normalfunction') !== 'true') {
      sessionStorage.setItem('chosenbook', 'false');

      // *********** perform database operation here
      // before closing the browser ************** //
      backRefreshBrowserClosed();
      // added the delay otherwise database operation will not work
      for (var i = 0; i < 5000000000; i++) {}
    }
  };
}

if (noBooksAvailablePage) {
  const refreshButton = document.querySelector(
    '.refresh-page_no-books-available'
  );
  refreshButton.addEventListener('click', () => {
    refreshButton.href = location.href;
    loadingModal();
  });

  const resetButton = document.querySelector(
    '.reset-rejected-books_no-books-available'
  );
  resetButton.addEventListener('click', () => {
    console.log('Does this work main.js');
    loadingModal();
    resetRejectedBookList(location.href);
  });
}

///////////////////////////////////////////////////////////////
// Return Random Book
//////////////////////////////////////////////////////////////

if (returnBookForm) {
  const returnBookBtn = document.querySelector('.return-book-btn');
  returnBookBtn.addEventListener('click', returnBook);
}

//////////////////////////////////////////////////////////////
// Post Book Review
//////////////////////////////////////////////////////////////

if (postReviewForm) {
  postReviewForm.addEventListener('submit', (e) => {
    e.preventDefault();
    const personalFeedback = document.getElementById('personal-feedback').value;
    const privateFeedback = document.getElementById('private-feedback').value;
    const amazonReviewTitle = document.getElementById('amazon-review-title')
      .value;
    const amazonMarketplace = document.querySelector(
      '.dropdown-select_post-review'
    ).value;
    const amazonUsername = document.getElementById('username_post-review')
      .value;
    const goodreadsLink = document.getElementById('goodreads-review-link')
      .value;
    const bookbubLink = document.getElementById('bookbub-review-link').value;
    let wordCount = 0;
    if (personalFeedback !== '') {
      wordCount = personalFeedback.match(/(\w+)/g).length;
    }

    const errorMessages = document.querySelectorAll('.input-p_contact-us');
    const updateVisibility = (num, type) => {
      errorMessages[num].style.display = type;
    };
    if (
      wordCount < 150 ||
      !personalFeedback ||
      !amazonReviewTitle ||
      amazonMarketplace === 'Select…' ||
      !goodreadsLink
    ) {
      if (!wordCount || wordCount < 150) {
        updateVisibility(0, 'block');
      } else {
        updateVisibility(0, 'none');
      }
      if (!amazonReviewTitle) {
        updateVisibility(1, 'block');
      } else {
        updateVisibility(1, 'none');
      }
      if (amazonMarketplace === 'Select…') {
        updateVisibility(2, 'block');
      } else {
        updateVisibility(2, 'none');
      }
      if (!goodreadsLink) {
        updateVisibility(3, 'block');
      } else {
        updateVisibility(3, 'none');
      }
    } else {
      console.log(
        personalFeedback,
        privateFeedback,
        amazonReviewTitle,
        amazonMarketplace,
        amazonUsername,
        goodreadsLink,
        bookbubLink
      );
      postReview(
        personalFeedback,
        privateFeedback,
        amazonReviewTitle,
        amazonMarketplace,
        amazonUsername,
        goodreadsLink,
        bookbubLink
      );
      loadingModal();
    }
  });
}

if (authoriseReview) {
  let latestTimeAuthoriseReview = document.querySelector(
    '.latest-time_authorise-review'
  );

  let latestDate = latestTimeAuthoriseReview.textContent;
  const latestReviewExpires =
    new Date(latestDate).getTime() + 7 * 24 * 60 * 60 * 1000;
  let latestDateEvent = new Date(latestReviewExpires);

  setInterval(() => {
    if (latestReviewExpires < Date.now()) {
      location.assign('/dashboard');
    }
  }, 10000);

  let latestOptions = {
    minute: 'numeric',
    hour: 'numeric',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };
  latestDate = latestDateEvent.toLocaleString('en', latestOptions);
  latestTimeAuthoriseReview.textContent = latestDate;

  const submitReviewAuth = document.getElementById('authorise-review');
  const reviewId = submitReviewAuth.value;
  submitReviewAuth.addEventListener('click', (e) => {
    e.preventDefault;
    reviewConfirmationAuthorisation(reviewId);
  });
}

if (reviewExtension) {
  const backButton = document.querySelector('.a_review-extension');
  backButton.addEventListener('click', () => {
    window.history.back();
  });
}

if (getAllUserReviews) {
  const bookReviewCards = document.querySelectorAll('.book-under-review-info');
  if (bookReviewCards.length > 0) {
    for (let i = 0; i < bookReviewCards.length; i++) {
      const bookReviewCard = document.querySelectorAll(
        '.book-under-review-info'
      )[i];
      const bookStatus = bookReviewCard.querySelector('.book-status')
        .textContent;
      let k = 0;
      bookStatus == 'review pending' ? (k = 4) : (k = 2);
      for (let j = 0; j < k; j++) {
        if (j % 2 === 1) {
          const booksUnderReviewInfo = bookReviewCard.querySelectorAll(
            '.span-book-under-review-date_dashboard'
          )[j];
          let reviewEarliestDate = booksUnderReviewInfo.textContent;
          var reviewEarliestDateEvent = new Date(reviewEarliestDate);
          let reviewEarliestOptions;
          if (k === 2) {
            reviewEarliestOptions = {
              year: 'numeric',
              month: 'long',
              day: 'numeric',
              hour: 'numeric',
              minute: 'numeric',
            };
          } else if (k === 4) {
            reviewEarliestOptions = {
              year: 'numeric',
              month: 'long',
              day: 'numeric',
            };
          }
          reviewEarliestDate = reviewEarliestDateEvent.toLocaleString(
            'en',
            reviewEarliestOptions
          );
          booksUnderReviewInfo.textContent = reviewEarliestDate;
        }
      }
    }
  }
}

/////////////////////////////////////////////////////////////////////
// Update Book Credits Applied
/////////////////////////////////////////////////////////////////////

if (updateCreditForm) {
  // Initialize variables
  let usableCredits;
  let allocatedCredits = 0;
  let availableCredits = document.querySelector(
    '.unused-credits_update-credits'
  ).innerText;

  let unusedCredits = parseInt(availableCredits);
  let usedCreditsArray = [];
  let updateCreditsArray = [];
  const inputs = document.querySelectorAll('.credit-input_update-credits');
  const subtractButtons = document.querySelectorAll(
    '.js-spinner-horizontal-subtract'
  );
  const addButtons = document.querySelectorAll('.js-spinner-horizontal-add');

  //Add event listener to each book
  for (let i = 0; i < inputs.length; i++) {
    inputs[i].addEventListener('keypress', (event) => {
      console.log(inputs[i].textContent, '1');
      event.preventDefault();
    });
    allocatedCredits = parseInt(allocatedCredits) + parseInt(inputs[i].value);
    inputs[i].max = parseInt(availableCredits) + parseInt(inputs[i].value);
    console.log(inputs[i].textContent, '2');
  }
  for (let i = 0; i < subtractButtons.length; i++) {
    subtractButtons[i].addEventListener('click', () => {
      if (inputs[i].value > 0) {
        inputs[i].value = parseInt(inputs[i].value) - 1;
        console.log(inputs[i].textContent, '3');
        creditUpdateHandler(inputs[i].id);
      }
    });
  }
  for (let i = 0; i < addButtons.length; i++) {
    addButtons[i].addEventListener('click', () => {
      if (unusedCredits > 0) {
        inputs[i].value = parseInt(inputs[i].value) + 1;
        console.log(inputs[i].textContent, '4');
        creditUpdateHandler(inputs[i].id);
      }
    });
  }
  // Total number of credits available
  usableCredits = allocatedCredits + unusedCredits;
  const maxCredits = usableCredits;
  document.querySelector(
    '.available-credits_update-credits'
  ).textContent = usableCredits;
  if (usableCredits === 1) {
    document.querySelector('.proper-noun_update-credits').textContent =
      ' Credit';
  } else {
    document.querySelector('.proper-noun_update-credits').textContent =
      ' Credits';
  }
  const add = (a, b) => a + b;

  const calculateMaxInputValue = (id, creds) => {
    let otherInputsValues = 0;
    let otherInputs = [];
    const currentElement = document.getElementById(id);
    for (const input of inputs) {
      if (input.id != currentElement.id) {
        otherInputs.push(input);
      }
      console.log(inputs, '5');
    }
    otherInputs.forEach((element) => {
      otherInputsValues = parseInt(otherInputsValues) + parseInt(element.value);
      element.max = parseInt(element.value) + creds;
    });
    currentElement.max = usableCredits - otherInputsValues;
  };

  function creditUpdateHandler(id) {
    // swap out for usable credits
    if (usableCredits >= 0) {
      for (let i = 0; i < inputs.length; i++) {
        if (inputs[i].value === '' || inputs[i].value < 0) inputs[i].value = 0;
        usedCreditsArray.splice(i, 1, parseInt(inputs[i].value));
      }
      let sum = usedCreditsArray.reduce(add);
      unusedCredits = usableCredits - sum;
      calculateMaxInputValue(id, unusedCredits);
      document.querySelector(
        '.unused-credits_update-credits'
      ).textContent = unusedCredits;
    }
  }
  const updateBookCreditsBtn = document.querySelector(
    '.update-book-credits_update-credits'
  );

  updateBookCreditsBtn.addEventListener('click', () => {
    for (const el of inputs) {
      if (el.value === '' || el.value < 0) el.value = 0;
      updateCreditsArray.push(`${el.id}: ${el.value}`);
    }
    updateEachBooksCredits(updateCreditsArray, unusedCredits, maxCredits);
    loadingModal();
  });
}

if (contactUsForm) {
  const errorMessages = document.querySelectorAll('.input-p_contact-us');
  const updateVisibility = (num, type) => {
    errorMessages[num].style.display = type;
  };
  contactUsForm.addEventListener('submit', (e) => {
    e.preventDefault();
    const contactName = document.getElementById('name_contact-us').value;
    const contactEmail = document.getElementById('email_contact-us').value;
    const contactTitle = document.getElementById('title_contact-us').value;
    const contactSubject = document.getElementById(
      'what-is-this-regarding_contact-us'
    ).value;
    const contactMessage = document.getElementById('message_contact-us').value;
    if (
      !contactName ||
      !contactEmail ||
      !contactTitle ||
      !contactSubject ||
      !contactMessage
    ) {
      if (!contactName) {
        updateVisibility(0, 'block');
      } else {
        updateVisibility(0, 'none');
      }
      if (!contactEmail) {
        updateVisibility(1, 'block');
      } else {
        updateVisibility(1, 'none');
      }
      if (!contactTitle) {
        updateVisibility(2, 'block');
      } else {
        updateVisibility(2, 'none');
      }
      if (!contactSubject) {
        updateVisibility(3, 'block');
      } else {
        updateVisibility(3, 'none');
      }
      if (!contactMessage) {
        updateVisibility(4, 'block');
      } else {
        updateVisibility(4, 'none');
      }
    } else {
      contactUs(
        contactName,
        contactEmail,
        contactTitle,
        contactSubject,
        contactMessage
      );
    }
  });
}
//////////////////////////////////////////////////////////////
// Admin
/////////////////////////////////////////////////////////////
if (adminLoginForm) {
  adminLoginForm.addEventListener('submit', (e) => {
    e.preventDefault();
    const email = document.getElementById('inputEmail').value;
    const password = document.getElementById('inputPassword').value;
    adminLogin(email, password);
  });
}

if (adminDashboard) {
  window.addEventListener('click', (e) => {
    if (e.target.classList[1] === 'fa-save') {
      let messageEl = e.target.closest('a');
      let messageValue = messageEl.getAttribute('data-value');
      let messageElDiv = e.target.closest('div');
      const allSelect = messageElDiv.querySelectorAll('select');
      const messageStatus = allSelect[0].value;
      const inCharge = allSelect[1].value;
      const data = { messageStatus, inCharge };
      updateContactUsMessage(data, messageValue);
    }
  });
  const sendEmailButton = document.getElementById(
    'send-credit-email_admin-dashboard'
  );
  if (sendEmailButton) {
    sendEmailButton.addEventListener('click', () => {
      sendCreditsEmail();
    });
  }
  const checkCreditButton = document.getElementById('check-credit-button');
  let checkCreditValue = checkCreditButton.value;
  checkCreditValue = checkCreditValue === 'true';
  checkCreditButton.addEventListener('click', () => {
    checkCreditValue = checkCreditValue ? false : true;
    checkCreditValue = `checkValue=${checkCreditValue}`;
    checkCreditUpdate(checkCreditValue);
  });
}

if (adminUpdateContactUsForm) {
  const sendButton = document.getElementById(
    'send-button_query-admin-dashboard'
  );
  const sendId = sendButton.value;
  sendButton.addEventListener('click', () => {
    const allSelectOptions = document.querySelectorAll('select');
    const messageStatus = allSelectOptions[0].value;
    const inCharge = allSelectOptions[1].value;
    const notes = document.querySelector('textarea').value;
    const data = { messageStatus, inCharge, notes };
    updateContactUsMessage(data, sendId);
  });
}

if (adminCompletedQuerysContactUs) {
  const searchButton = document.getElementById('querySearchButton');
  const resetButton = document.getElementById('resetSearchButton');
  searchButton.addEventListener('click', () => {
    const selectKey = document.getElementById('querySearchOption').value;
    const inputValue = document.getElementById('querySearchInput').value;

    let url = new URL(window.location.href);
    url.searchParams.set(selectKey, inputValue);
    location.assign(url.href);
  });
  resetButton.addEventListener('click', () => {
    let url = new URL(window.location.href);
    url = url.origin + url.pathname;
    location.assign(url);
  });
}
if (adminOneUserInfoPage) {
  const updateBtn = document.getElementById('update-user-credit-button_admin');
  updateBtn.addEventListener('click', () => {
    const selectValue = document.querySelector('.select-option_admin').value;
    const creditValue = document.querySelector('.credit-value_admin').value;
    adminUpdateUserCredits(selectValue, creditValue, updateBtn.value);
  });
  const adminActivateUserAccount = document.getElementById(
    'activate-user-account'
  );
  if (adminActivateUserAccount) {
    const userId = location.pathname.split('/')[3];
    adminActivateUserAccount.addEventListener('click', () => {
      loadingModal();
      adminActivateUserAccountForm(userId);
    });
  }
}
if (getOneReviewAdmin) {
  // Update review dates
  const updateReviewButton = document.getElementById(
    'send-review-update_get-one-review'
  );
  const reviewId = updateReviewButton.value;
  updateReviewButton.addEventListener('click', () => {
    const daysToUpdateReview = document.getElementById('review-deadline-input')
      .value;
    const obj = { daysToExtend: daysToUpdateReview, idReview: reviewId };
    updateReviewDeadlineAdmin(obj);
  });

  // Change status
  const reviewStatusButton = document.getElementById(
    'review-status_get-one-review'
  );
  reviewStatusButton.addEventListener('click', () => {
    const obj = {
      changeStatus: reviewStatusButton.value,
      idReview: reviewId,
    };
    updateReviewDeadlineAdmin(obj);
  });

  // Recover Review
  const recoverReviewButton = document.getElementById(
    'recover-review_get-one-review'
  );
  if (recoverReviewButton) {
    recoverReviewButton.addEventListener('click', () => {
      const daysToAdd = document.getElementById('recover-review-input').value;
      const obj = {
        recoverReviewDays: daysToAdd,
        idReview: reviewId,
      };
      updateReviewDeadlineAdmin(obj);
    });
  }
}
if (getOneBookAdmin) {
  getOneBookAdmin.addEventListener('click', () => {
    const updateCreditsContainer = document.querySelector(
      '.add-book-credits-containers_get-one-book'
    );
    updateCreditsContainer.style.display === 'block'
      ? (updateCreditsContainer.style.display = 'none')
      : (updateCreditsContainer.style.display = 'block');
  });
  const updateBookCredits = document.querySelector(
    '.update-credits-button_get-one-book'
  );
  updateBookCredits.addEventListener('click', () => {
    const creds = document.querySelector('.number-credits_get-one-book').value;
    const bookId = updateBookCredits.value;
    const obj = {
      creditsApplied: creds,
      bookId: bookId,
    };
    adminAddBookCredits(obj);
  });
}
if (superAdmin) {
  let status;
  const startButton = document.querySelector('.start-button_super-admin');
  const stopButton = document.querySelector('.stop-button_super-admin');
  startButton.addEventListener('click', () => {
    status = true;
  });
  stopButton.addEventListener('click', () => {
    status = false;
  });
  checkAllReviewStatus();
  setInterval(() => {
    if (status === true) {
      console.log('front end');
      checkAllReviewStatus();
    }
  }, 300000);
}
if (importantChecks) {
  setInterval(() => {
    location.reload();
    loadingModal();
  }, 600000);
}

if (verifyAuthorAdminPage) {
  const verifyAuthorAdminButton = document.querySelectorAll(
    '.verify-author_admin-button'
  );
  verifyAuthorAdminButton.forEach((el) => {
    el.addEventListener('click', () => {
      const value = el.value;
      loadingModal();
      verifyAuthor_Admin(value);
    });
  });
}
if (verifyUserAdminPage) {
  const verifyUserAdminButton = document.querySelectorAll(
    '.verify-user_admin-button'
  );
  verifyUserAdminButton.forEach((el) => {
    el.addEventListener('click', () => {
      const value = el.value;
      console.log(value);
      loadingModal();
      verifyUser_Admin(value);
    });
  });
}
if (verifyBookAdminPage) {
  const verifyBookAdminButton = document.querySelectorAll(
    '.verify-book_admin-button'
  );
  verifyBookAdminButton.forEach((el) => {
    el.addEventListener('click', () => {
      const value = el.value;
      console.log(value);
      loadingModal();
      verifyBook_Admin(value);
    });
  });
}
if (faqsPage) {
  document.getElementById('searchInput').addEventListener('input', function () {
    let filter = this.value.toUpperCase();
    let faqItems = document.getElementsByClassName('accordion-container');

    for (let i = 0; i < faqItems.length; i++) {
      let txtValue = faqItems[i].textContent || faqItems[i].innerText;
      if (txtValue.toUpperCase().indexOf(filter) > -1) {
        faqItems[i].style.display = '';
      } else {
        faqItems[i].style.display = 'none';
      }
    }
  });
}

/////////////////////////////////////
// WS
/////////////////////////////////////
let HOST;
if (location.hostname === 'www.bookroar.com') {
  HOST = location.origin.replace(/^https/, 'wss');
  // console.log('bookroar prod', HOST);
} else if (location.hostname === '127.0.0.1') {
  HOST = location.origin.replace(/^http/, 'ws');
  // console.log('dev production', HOST);
}
// var ws = new WebSocket(HOST);
// console.log(ws);
// ws.onmessage = function (event) {
//   ws.send('This is a message from the websocket');
//   console.log('message alert');
// };
