import axios from 'axios';
import { showAlert } from './alerts.js';

export const deleteBook = async (id) => {
  try {
    const res = await axios({
      method: 'PATCH',
      url: `/api/v1/your-books/delete/${id}`,
    });

    if (res.data.status === 'success') {
      showAlert('success', 'Book has been deleted');
      window.setTimeout(() => {
        location.assign('/dashboard');
      }, 100);
    }
  } catch (error) {
    showAlert('error', error.response.data.msg);
  }
};
