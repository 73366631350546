import axios from 'axios';
import { showAlert } from './alerts.js';

export const postReview = async (
  personalFeedback,
  privateFeedback,
  amazonReviewTitle,
  amazonMarketplace,
  amazonUsername,
  goodreadsReviewLink,
  bookbubReviewLink
) => {
  try {
    console.log(
      personalFeedback,
      privateFeedback,
      amazonReviewTitle,
      amazonMarketplace,
      amazonUsername,
      goodreadsReviewLink,
      bookbubReviewLink
    );

    const res = await axios({
      method: 'PATCH',
      url: '/api/v1/users/postReview',
      data: {
        amazonReviewTitle,
        amazonMarketplace,
        amazonUsername,
        goodreadsReviewLink,
        bookbubReviewLink,
        personalFeedback,
        privateFeedback,
      },
    });

    if (res.data.status === 'success') {
      const modal = document.getElementsByClassName('modal_post-review')[0];
      modal.style.display = 'block';
      window.setTimeout(() => {
        location.assign('/dashboard');
      }, 1000);
    }
  } catch (err) {
    showAlert('error', err.response.data.msg);
  }
};

export const reviewConfirmationAuthorisation = async (id) => {
  try {
    const res = await axios({
      method: 'PATCH',
      url: `/api/v1/users/reviews/${id}`,
    });
    if (res.data.status === 'success') {
      showAlert('success', 'Confirmation Sent');
      window.setTimeout(() => {
        location.assign('/dashboard');
      }, 500);
    }
  } catch (error) {
    showAlert('error', error.response.data.msg);
  }
};
