import axios from 'axios';
import { showAlert } from './alerts.js';

export const signup = async (
  firstName,
  lastName,
  username,
  email,
  password,
  passwordConfirm
) => {
  try {
    // Removed the start of the URL because the API and the website are using the same URL.
    const res = await axios({
      method: 'POST',
      url: '/api/v1/users/signup',
      data: {
        firstName,
        lastName,
        username,
        email,
        password,
        passwordConfirm,
      },
    });
    if (res.data.status === 'success') {
      showAlert('success', 'You signed up successfully');
      window.setTimeout(() => {
        location.assign('/activate-account');
      }, 1500);
    }
  } catch (error) {
    showAlert('error', error.response.data.msg);
  }
};

export const activateNewUserAccountReq = async (token) => {
  try {
    const res = await axios({
      method: 'PATCH',
      url: `/api/v1/users/new-user/activateAccount/${token}`,
    });
    if (res.data.status === 'success') {
      showAlert('success', 'Account activated!!');
      window.setTimeout(() => {
        location.assign('/login');
      }, 1500);
    }
  } catch (error) {
    showAlert('error', error.response.data.msg);
  }
};

export const login = async (email, password) => {
  try {
    const res = await axios({
      method: 'POST',
      url: '/api/v1/users/login',
      data: {
        email,
        password,
      },
    });
    if (res.data.status === 'success') {
      showAlert('success', 'Login successful!!');
      window.setTimeout(() => {
        location.assign('/dashboard');
      }, 1500);
    }
  } catch (error) {
    showAlert('error', error.response.data.msg);
  }
};

export const logout = async () => {
  try {
    const res = await axios({
      method: 'GET',
      url: '/api/v1/users/logout',
    });
    if (res.data.status === 'success') {
      location.replace('/login');
    }
  } catch (err) {
    showAlert('error', 'Error logging out');
  }
};

export const addCreditBeforeLogout = async () => {
  try {
    const res = await axios({
      method: 'PATCH',
      url: '/api/v1/users/addCreditBeforeLogout',
    });
    if (res.data.status === 'success') {
      const res2 = await axios({
        method: 'GET',
        url: '/api/v1/users/logout',
      });
      if (res2.data.status === 'success') {
        location.replace('/login');
      }
    }
  } catch (err) {
    showAlert('error', 'Error adding credit and logging out');
  }
};

export const forgotPassword = async (data) => {
  try {
    const res = await axios({
      method: 'POST',
      url: '/api/v1/users/forgotPassword',
      data,
    });
    if (res.data.status === 'success') {
      showAlert('success', 'Success!!');
      window.setTimeout(() => {
        location.assign('/forgotPasswordConfirmation');
      }, 200);
    }
  } catch (err) {
    showAlert('error', err.response.data.msg);
  }
};

export const passwordReset = async (data, token, type) => {
  try {
    const res = await axios({
      method: 'PATCH',
      url: `/api/v1/users/resetPassword/${token}`,
      data,
    });
    if (res.data.status === 'success') {
      showAlert('success', 'Password changed!');
      window.setTimeout(() => {
        location.assign('/dashboard');
      }, 1000);
    }
  } catch (err) {
    showAlert('error', ' Error updating password!');
  }
};
export const reactivateAccountSendEmail = async (email) => {
  try {
    const res = await axios({
      method: 'PATCH',
      url: `/api/v1/users/sendReactivateAccountLink`,
      data: { email },
    });
    if (res.data.status === 'success') {
      showAlert('success', 'Reactivate link has been sent to your email');
      window.setTimeout(() => {
        location.assign('/');
      }, 1000);
    }
  } catch (err) {
    showAlert('error', err.response.data.msg);
  }
};

export const reactivateUserAccount = async (token) => {
  try {
    const res = await axios({
      method: 'PATCH',
      url: `/api/v1/users/activateAccount/${token}`,
    });
    if (res.data.status === 'success') {
      showAlert(
        'success',
        'Account has been successfully reactivated. You can now log in to your account.'
      );
      window.setInterval(() => {
        location.assign('/login');
      }, 2000);
    }
  } catch (err) {
    showAlert('error', err.response.data.msg);
  }
};
