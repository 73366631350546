import axios from 'axios';
import { showAlert } from './alerts.js';

// Remove
export const updateUserInfo = async (data, type) => {
  try {
    // Removed the start of the URL because the API and the website are using the same URL.
    const res = await axios({
      method: 'PATCH',
      url: '/api/v1/users/updateMe',
      data,
    });
    if (res.data.status === 'success') {
      showAlert('success', 'Information updated successfully');
      window.setTimeout(() => {
        location.assign('/addBook');
      }, 500);
    }
  } catch (error) {
    showAlert('error', error.response.data.msg);
  }
};

export const addBookInfo = async (data, type) => {
  try {
    const res = await axios({
      method: 'POST',
      url: '/api/v1/your-books',
      data,
    });
    if (res.data.status === 'success') {
      showAlert('success', 'Book Created');
      window.setTimeout(() => {
        location.assign('/dashboard');
      }, 1500);
    }
  } catch (error) {
    showAlert('error', error.response.data.msg);
  }
};

export const updateBookInfo = async (data, type, id) => {
  try {
    const res = await axios({
      method: 'PATCH',
      url: `/api/v1/your-books/${id}`,
      data,
    });
    if (res.data.status === 'success') {
      showAlert('success', 'Book updated');
      window.setTimeout(() => {
        location.assign('/dashboard');
      }, 500);
    }
  } catch (error) {
    showAlert('error', error.response.data.msg);
  }
};

export const updateEachBooksCredits = async (
  array,
  unusedCredits,
  maxCredits
) => {
  try {
    const res = await axios({
      method: 'PATCH',
      url: `/api/v1/your-books/updateBookCredits`,
      data: {
        array,
        unusedCredits,
        maxCredits,
      },
    });
    if (res.data.status == 'success') {
      showAlert('success', 'Credits updated!!');
      window.setTimeout(() => {
        location.assign('/dashboard');
      }, 500);
    }
  } catch (error) {
    showAlert('error', error.response.data.msg);
  }
};

export const updateUserInformation = async (data, type) => {
  const url =
    type === 'password'
      ? '/api/v1/users/updateMyPassword'
      : '/api/v1/users/updateMe';
  try {
    const res = await axios({
      method: 'PATCH',
      url,
      data,
    });
    if (res.data.status === 'success') {
      showAlert('success', 'Information updated successfully');
      window.setTimeout(() => {
        location.assign('/update-profile/user-info');
      }, 500);
    }
  } catch (error) {
    showAlert('error', 'Make sure all required fields are correctly filled in');
  }
};
