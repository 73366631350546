import axios from 'axios';
import { showAlert } from './alerts.js';

export const adminUpdateUserCredits = async (option, value, id) => {
  try {
    const res = await axios({
      method: 'PATCH',
      url: '/api/v1/admin_account/adminUpdateUserCredits/:id',
      data: {
        option,
        value,
        id,
      },
    });
    if (res.data.status === 'success') {
      showAlert('success', 'Credits updated!!');
      setTimeout(() => {
        location.reload();
      }, 1000);
    }
  } catch (error) {
    showAlert('error', error.response.data.msg);
  }
};
export const adminAddBookCredits = async (data) => {
  try {
    const res = await axios({
      method: 'PATCH',
      url: `/api/v1/admin_account/get-all-books/updateBookCredits`,
      data,
    });
    if (res.data.status === 'success') {
      showAlert('success', 'Book credits updated successfully');
      location.reload();
    }
  } catch (error) {
    showAlert('error', error.response.data.msg);
  }
};
export const getLatestQueryAdmin = async (key, value) => {
  try {
    const res = await axios({
      method: 'GET',
      url: '/admin_account/update-author-profile',
      data: { key, value },
    });
  } catch (error) {
    showAlert('error', error.response.data.msg);
  }
};
