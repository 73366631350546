import axios from 'axios';
import { showAlert } from './alerts.js';

export const adminLogin = async (email, password) => {
  try {
    const res = await axios({
      method: 'POST',
      url: '/api/v1/admin_account/login',
      data: {
        email,
        password,
      },
    });
    if (res.data.status === 'success') {
      showAlert('success', 'Login successful!!');
      window.setTimeout(() => {
        location.assign('/admin_account/dashboard');
      }, 1500);
    }
  } catch (error) {
    showAlert('error', error.response.data.msg);
  }
};

export const updateContactUsMessage = async (data, id) => {
  try {
    const res = await axios({
      method: 'PATCH',
      url: `/api/v1/admin_account/contact-us/${id}`,
      data,
    });
    if (res.data.status === 'success') {
      showAlert('success', 'Status Updated');
      window.setTimeout(() => {
        location.assign('/admin_account/dashboard');
      }, 500);
    }
  } catch (error) {
    showAlert('error', error.response.data.msg);
  }
};

export const updateReviewDeadlineAdmin = async (data) => {
  try {
    const res = await axios({
      method: 'PATCH',
      url: `/api/v1/admin_account/extendReviewDeadline`,
      data,
    });
    if (res.data.status === 'success') {
      showAlert('success', 'Status Updated');
      location.reload();
    }
  } catch (error) {
    showAlert('error', error.response.data.msg);
  }
};
export const sendCreditsEmail = async () => {
  try {
    const res = await axios({
      method: 'PATCH',
      url: `/api/v1/admin_account/sendCreditsEmail`,
    });
    if (res.data.status === 'success') {
      showAlert('success', 'Status Updated');
    }
  } catch (error) {
    showAlert('error', error.response.data.msg);
  }
};
// export const resetGenres = async () => {
//   try {
//     const res = await axios({
//       method: 'PATCH',
//       url: `/api/v1/admin_account/resetUserGenres`,
//     });
//     if (res.data.status === 'success') {
//       showAlert('success', 'Status Updated');
//     }
//   } catch (error) {
//     showAlert('error', error.response.data.msg);
//   }
// };
export const checkAllReviewStatus = async () => {
  try {
    const res = await axios({
      method: 'PATCH',
      url: `/api/v1/admin_account/dashboard/superUser`,
    });
    if (res.data.status === 'success') {
      showAlert('success', 'Status Updated');
    }
  } catch (error) {
    showAlert('error', error.response.data.msg);
  }
};

export const checkCreditUpdate = async (value) => {
  location.assign(`/admin_account/dashboard?${value}`);
};

export const adminActivateUserAccountForm = async (id) => {
  try {
    const res = await axios({
      method: 'PATCH',
      url: `/api/v1/admin_account/activate-author-account/${id}`,
    });
    if (res.data.status === 'success') {
      showAlert('success', 'Status Updated');
      window.setTimeout(() => {
        location.assign(`/admin_account/get-all-users/${id}`);
      }, 500);
    }
  } catch (error) {
    showAlert('error', error.response.data.msg);
  }
};
