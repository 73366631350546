import axios from 'axios';
import { showAlert } from './alerts.js';

export const contactUs = async (name, email, title, subject, message) => {
  try {
    const res = await axios({
      method: 'POST',
      url: '/api/v1/users/contact-us',
      data: {
        name,
        email,
        title,
        subject,
        message,
      },
    });
    if (res.data.status === 'success') {
      showAlert('success', 'Message Sent!!');
      window.setTimeout(() => {
        location.assign('/dashboard');
      }, 1500);
    }
  } catch (error) {
    showAlert('error', error.response.data.msg);
  }
};
